<template>
	<div>
		<div  style="background: #f5f5f5;">
			<top :windowWidth="windowWidth" :windowHeight="windowHeight"></top>
			<right v-if="windowWidth>=1200"></right>
			<banner :windowWidth="windowWidth" :windowHeight="windowHeight"></banner>
			<table-view :windowWidth="windowWidth" :windowHeight="windowHeight"></table-view>
			<bottom v-if="windowWidth>=1200"></bottom>
		</div>
	</div>
</template>

<script>
	import top from '@/components/top/index.vue';
	import right from '@/components/right/index.vue';
	import banner from './components/processShow/banner.vue';
	import tableView from './components/processShow/tableView.vue';
	import bottom from '@/components/bottom/index.vue';
	export default {
		components:{
			top,
			banner,
			tableView,
			bottom,
			right
		},
		data() {
			return {
				// 浏览器窗口宽度高度
				windowWidth: 0,
				windowHeight: 0,
			}
		},
		
		created() {
			
		},
		
		mounted() {
			let that = this;
			that.windowWidth = document.body.clientWidth;
			that.windowHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					this.windowWidth = document.body.clientWidth;
					this.windowHeight = document.body.clientHeight;
				})();
			};
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
</style>