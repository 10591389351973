<template>
	<div>
		<div class="banner">
			<div style="">
				<img style="width: 100%;height: 100%;" :src="img" />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/craft-show-banner.png',
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		},
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.banner{
			position: relative;
			text-align: center;
			background-color: #171c1f;
			padding: 0 200px;
		}
	}
	
	@media only screen and (max-width:1920px) {
		.banner{
			position: relative;
			text-align: center;
			background-color: #171c1f;
			padding: 0 200px;
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.banner{
			position: relative;
			text-align: center;
			background-color: #171c1f;
			padding: 0 140px;
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.banner{
			position: relative;
			text-align: center;
			background-color: #171c1f;
			padding: 0 30px;
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.banner{
			margin-top: 60px;
			position: relative;
			text-align: center;
			background-color: #171c1f;
			padding: 0 30px;
		}
	}
	
	@media only screen and (max-width:767px) {
		.banner{
			margin-top: 60px;
			position: relative;
			text-align: center;
			background-color: #171c1f;
			padding: 0 30px;
		}
	}
	
</style>