<template>
	<div>
		<div class="tableView">
			<div class="tv_div">
				<table class="ui_table">
					<tbody class="ui_tb_tbody">
						<tr class="ui_tb_td_gaugeOutfit">
							<th style="width: 20%;"></th>
							<th style="width: 30%;"></th>
							<th style="width: 50%;"></th>
						</tr>
						<tr class="ui_tb_td_capability">
							<td class="ui_tb_td_cb_text1" style="background: #767676;color: #fff;width: 20%;">{{$t('factoryDisplay.processShow.gaugeOutfit1')}}</td>
							<td class="ui_tb_td_cb_text1" style="background: #767676;color: #fff;width: 30%;">{{$t('factoryDisplay.processShow.gaugeOutfit2')}}</td>
							<td class="ui_tb_td_cb_text1" style="background: #767676;color: #fff;width: 50%;">{{$t('factoryDisplay.processShow.gaugeOutfit3')}}</td>
						</tr>
						<tr class="ui_tb_td_capability" v-for="(item,index) in $t('factoryDisplay.processShow.list')" :key="index">
							<td class="ui_tb_td_cb_text1" style="width: 20%;" v-if="item.title!==''" :rowspan="item.lengthIndex">{{item.title}}</td>
							<td class="ui_tb_td_cb_text2" style="width: 30%;" v-if="item.name!==''" :rowspan="item.textType==true ? item.towLengthIndex : ''">{{item.name}}</td>
							<td class="ui_tb_td_cb_text2" style="width: 50%;" v-if="item.text!==''">{{item.text}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.tableView{
			padding: 0 200px;
			
			.tv_div{
				background: #fff;
				padding: 20px;
				overflow-x: scroll;
				overflow-y: hidden;
				
				.ui_table{
					margin-top: 10px;
					width: 100%;
					position: relative;
					z-index: 2;
					border-collapse: collapse;
					margin-top: 20px;
					
					.ui_tb_tbody{
						display: table-row-group;
						vertical-align: middle;
						border-color: inherit;
						
						.ui_tb_td_gaugeOutfit{
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
						}
						
						.ui_tb_td_capability{
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
							z-index: 1;
							position: relative;
							
							.ui_tb_td_cb_text1{
								border: 1px solid #e7e7e7;
								background: #f2f3f7;
								padding: 10px 0;
								text-align: center;
							}
							
							.ui_tb_td_cb_text2{
								border: 1px solid #e7e7e7;
								padding: 10px 0;
								text-align: center;
								font-size: 12px;
								color: #333333;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.tableView{
			padding: 0 200px;
			
			.tv_div{
				background: #fff;
				padding: 20px;
				overflow-x: scroll;
				overflow-y: hidden;
				
				.ui_table{
					margin-top: 10px;
					width: 100%;
					position: relative;
					z-index: 2;
					border-collapse: collapse;
					margin-top: 20px;
					
					.ui_tb_tbody{
						display: table-row-group;
						vertical-align: middle;
						border-color: inherit;
						
						.ui_tb_td_gaugeOutfit{
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
						}
						
						.ui_tb_td_capability{
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
							z-index: 1;
							position: relative;
							
							.ui_tb_td_cb_text1{
								border: 1px solid #e7e7e7;
								background: #f2f3f7;
								padding: 10px 0;
								text-align: center;
							}
							
							.ui_tb_td_cb_text2{
								border: 1px solid #e7e7e7;
								padding: 10px 0;
								text-align: center;
								font-size: 12px;
								color: #333333;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.tableView{
			padding: 0 140px;
			
			.tv_div{
				background: #fff;
				padding: 20px;
				overflow-x: scroll;
				overflow-y: hidden;
				
				.ui_table{
					margin-top: 10px;
					width: 100%;
					position: relative;
					z-index: 2;
					border-collapse: collapse;
					margin-top: 20px;
					
					.ui_tb_tbody{
						display: table-row-group;
						vertical-align: middle;
						border-color: inherit;
						
						.ui_tb_td_gaugeOutfit{
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
						}
						
						.ui_tb_td_capability{
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
							z-index: 1;
							position: relative;
							
							.ui_tb_td_cb_text1{
								border: 1px solid #e7e7e7;
								background: #f2f3f7;
								padding: 10px 0;
								text-align: center;
							}
							
							.ui_tb_td_cb_text2{
								border: 1px solid #e7e7e7;
								padding: 10px 0;
								text-align: center;
								font-size: 12px;
								color: #333333;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.tableView{
			padding: 0 30px;
			
			.tv_div{
				background: #fff;
				padding: 20px;
				overflow-x: scroll;
				overflow-y: hidden;
				
				.ui_table{
					margin-top: 10px;
					width: 100%;
					position: relative;
					z-index: 2;
					border-collapse: collapse;
					margin-top: 20px;
					
					.ui_tb_tbody{
						display: table-row-group;
						vertical-align: middle;
						border-color: inherit;
						
						.ui_tb_td_gaugeOutfit{
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
						}
						
						.ui_tb_td_capability{
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
							z-index: 1;
							position: relative;
							
							.ui_tb_td_cb_text1{
								border: 1px solid #e7e7e7;
								background: #f2f3f7;
								padding: 10px 0;
								text-align: center;
							}
							
							.ui_tb_td_cb_text2{
								border: 1px solid #e7e7e7;
								padding: 10px 0;
								text-align: center;
								font-size: 12px;
								color: #333333;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.tableView{
			padding: 0 30px;
			
			.tv_div{
				background: #fff;
				padding: 20px;
				overflow-x: scroll;
				overflow-y: hidden;
				
				.ui_table{
					margin-top: 10px;
					width: 100%;
					position: relative;
					z-index: 2;
					border-collapse: collapse;
					margin-top: 20px;
					
					.ui_tb_tbody{
						display: table-row-group;
						vertical-align: middle;
						border-color: inherit;
						
						.ui_tb_td_gaugeOutfit{
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
						}
						
						.ui_tb_td_capability{
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
							z-index: 1;
							position: relative;
							
							.ui_tb_td_cb_text1{
								border: 1px solid #e7e7e7;
								background: #f2f3f7;
								padding: 10px;
								text-align: center;
							}
							
							.ui_tb_td_cb_text2{
								border: 1px solid #e7e7e7;
								padding: 10px;
								text-align: center;
								font-size: 12px;
								color: #333333;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.tableView{
			padding: 0 30px;
			
			.tv_div{
				background: #fff;
				overflow-x: scroll;
				overflow-y: hidden;
				
				.ui_table{
					margin-top: 10px;
					position: relative;
					z-index: 2;
					border-collapse: collapse;
					margin-top: 20px;
					
					.ui_tb_tbody{
						width: calc(100vw - 100px);
						display: table-row-group;
						vertical-align: middle;
						border-color: inherit;
						
						.ui_tb_td_gaugeOutfit{
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
						}
						
						.ui_tb_td_capability{
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
							z-index: 1;
							position: relative;
							
							.ui_tb_td_cb_text1{
								border: 1px solid #e7e7e7;
								background: #f2f3f7;
								padding: 10px;
								text-align: center;
							}
							
							.ui_tb_td_cb_text2{
								border: 1px solid #e7e7e7;
								padding: 10px;
								text-align: center;
								font-size: 12px;
								color: #333333;
							}
						}
					}
				}
			}
		}
	}
	
</style>